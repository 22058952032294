import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  AddLabels,
  AvailableLabels,
  Button,
  Card,
  EmptyNote,
  TextWithLabels,
  Layout,
  Loader,
  TabsContent,
  TabsRoot
} from 'components'
import {
  useGetControversy,
  useGetCase,
  useUpdateCase,
  useGetUsersWithLabeling,
  useCreateCaseBusinessUnit,
  useDeleteCaseBusinessUnit,
  useGetCaseBusinessUnits,
  useGetBusinessUnits
} from 'data-fetcher'
import { CaseStatus, Label } from 'types'

import { Documents, Links, Notes } from './components'

import {
  BusinessUnitName,
  CaseBusinessUnitsWrapper,
  DeleteButton,
  DeleteIcon,
  ResponsibleBusinessUnitsHeadline,
  Rows,
  Select,
  SelectedBusinessUnitRow,
  SelectBusinessUnitWrapper,
  TabsList,
  Wrapper
} from './Case.styles'
import { useAuth } from 'stores'

const STATUSES: Array<{ label: string; value: CaseStatus }> = [
  { label: 'New', value: CaseStatus.New },
  { label: 'In Progress', value: CaseStatus.InProgress },
  { label: 'Review', value: CaseStatus.Review },
  { label: 'Not Relevant', value: CaseStatus.NotRelevant },
  { label: 'Closed', value: CaseStatus.Closed }
]

const Case = () => {
  const params = useParams()

  const { userId: currentUserId } = useAuth()

  const [activeFingerprintId, setActiveFingerprintId] = useState<string | undefined>()
  const [activeFingerprintName, setActiveFingerprintName] = useState<string | undefined>()
  const [activeLabel, setActiveLabel] = useState<Label | undefined>()
  const [hoveredLabelingId, setHoveredLabelingId] = useState<string | undefined>()
  const [selectedBusinessUnitId, setSelectedBusinessUnitId] = useState<string | undefined>(
    undefined
  )

  const caseId = params?.id

  const { data: controversyCase, isLoading: isLoadingCase } = useGetCase(caseId)
  const { data: controversy, isLoading: isLoadingControversy } = useGetControversy(
    controversyCase?.controversy
  )
  const { data: usersWithLabeling = [] } = useGetUsersWithLabeling({
    controversyId: controversyCase?.controversy
  })
  const { mutate: updateCase } = useUpdateCase()

  const { data: businessUnits = [] } = useGetBusinessUnits()
  const { data: caseBusinessUnits } = useGetCaseBusinessUnits({
    caseId: caseId!,
    options: { disabled: !caseId }
  })
  const { mutate: createCaseBusinessUnit } = useCreateCaseBusinessUnit()
  const { mutate: deleteCaseBusinessUnit } = useDeleteCaseBusinessUnit()

  const handleLabelClick = ({
    fingerprintId,
    fingerprintName,
    label
  }: {
    fingerprintId: string
    fingerprintName: string
    label: Label
  }) => {
    if (activeLabel?.id === label.id) {
      setActiveFingerprintId(undefined)
      setActiveFingerprintName(undefined)
      setActiveLabel(undefined)
    } else {
      setActiveFingerprintId(fingerprintId)
      setActiveFingerprintName(fingerprintName)
      setActiveLabel(label)
    }
  }

  const handleStatusChange = (updatedStatus: string) => {
    updateCase({ caseId: caseId!, updatedCase: { status: updatedStatus as CaseStatus } })
  }

  const handleAddBusinessUnit = () => {
    const selectedBusinessUnit = businessUnits.find(({ id }) => id === selectedBusinessUnitId)

    if (
      caseId &&
      selectedBusinessUnitId &&
      selectedBusinessUnit &&
      !caseBusinessUnits?.find(({ businessUnitId }) => businessUnitId === selectedBusinessUnitId)
    ) {
      createCaseBusinessUnit({
        businessUnitId: selectedBusinessUnit.id,
        businessUnitName: selectedBusinessUnit.name,
        caseId
      })

      setSelectedBusinessUnitId(undefined)
    }
  }

  const tabs = useMemo(
    () =>
      usersWithLabeling
        .filter(userId => userId !== currentUserId)
        .map((userId, i) => ({ name: `Colleague ${i + 1}`, value: userId })),
    [usersWithLabeling]
  )

  return (
    <Layout goBackHref="/cases">
      {isLoadingControversy || isLoadingCase ? (
        <Loader fullPageCenter />
      ) : !controversy ? (
        <EmptyNote copy="No data!" fullPageCenter icon="data" />
      ) : (
        <Wrapper>
          <Rows>
            <TabsRoot defaultValue="default">
              <TabsList tabs={[{ name: 'My Labels', value: 'default' }, ...tabs]} />
              <TabsContent value="default">
                <Card title="Insight">
                  <TextWithLabels
                    activeFingerprintId={activeFingerprintId}
                    activeFingerprintName={activeFingerprintName}
                    activeLabel={activeLabel}
                    controversy={controversy}
                    focusedLabelingId={hoveredLabelingId}
                    showReadMoreAfter={420}
                  />
                </Card>
              </TabsContent>
              {tabs.map(({ value }) => (
                <TabsContent value={value}>
                  <Card>
                    <TextWithLabels
                      activeFingerprintId={activeFingerprintId}
                      activeFingerprintName={activeFingerprintName}
                      activeLabel={activeLabel}
                      controversy={controversy}
                      showReadMoreAfter={420}
                      userId={value}
                    />
                  </Card>
                </TabsContent>
              ))}
            </TabsRoot>
            <Card title="Measure">
              <Notes caseId={caseId!} />
            </Card>
            <Card title="Documents">
              <Documents caseId={caseId!} />
            </Card>
          </Rows>
          <Rows>
            <Card title="Status">
              <Select
                ariaLabel="Status"
                onValueChange={handleStatusChange}
                placeholder="Status"
                value={controversyCase?.status}
                values={STATUSES}
              />
            </Card>
            <Card title="Responsibility">
              <SelectBusinessUnitWrapper>
                <Select
                  ariaLabel="Business unit"
                  onValueChange={(id: string) => setSelectedBusinessUnitId(id)}
                  placeholder="Business unit"
                  value={undefined}
                  values={businessUnits?.map(({ id, name }) => ({ label: name, value: id }))}
                />
                <Button
                  disabled={Boolean(
                    caseBusinessUnits?.find(
                      ({ businessUnitId }) => businessUnitId === selectedBusinessUnitId
                    )
                  )}
                  onClick={handleAddBusinessUnit}
                >
                  Add
                </Button>
              </SelectBusinessUnitWrapper>

              {Boolean(caseBusinessUnits?.length) && (
                <CaseBusinessUnitsWrapper>
                  <ResponsibleBusinessUnitsHeadline>
                    Selected Business Units:
                  </ResponsibleBusinessUnitsHeadline>
                  {caseBusinessUnits!.map(({ businessUnitId, businessUnitName }) => (
                    <SelectedBusinessUnitRow>
                      <BusinessUnitName>{businessUnitName}</BusinessUnitName>
                      <DeleteButton
                        onClick={() => deleteCaseBusinessUnit({ businessUnitId, caseId: caseId! })}
                      >
                        <DeleteIcon />
                      </DeleteButton>
                    </SelectedBusinessUnitRow>
                  ))}
                </CaseBusinessUnitsWrapper>
              )}
            </Card>
            <Card title="Labels">
              <AvailableLabels
                controversyId={controversy.id}
                onLabelingHovered={({ hovered, labeling }) => {
                  /**
                   * The received order of (un)hovered items does not always reflect
                   * the actual order. That's why we need explicit checks
                   * and callbacks in setState.
                   */
                  setHoveredLabelingId(prev => {
                    if (hovered) {
                      if (labeling) {
                        return labeling
                      }
                    } else {
                      if (labeling && prev && labeling === prev) {
                        return undefined
                      }
                    }

                    return prev
                  })
                }}
              />
            </Card>
            <Card title="Add Labels">
              <AddLabels activeLabelId={activeLabel?.id} onLabelClick={handleLabelClick} />
            </Card>
            <Card title="Links">
              <Links
                additionalLinks={controversyCase?.links}
                caseId={caseId!}
                source={{
                  name: controversy.documentType
                    ? `${controversy.source} - ${controversy.documentType}`
                    : controversy.source,
                  url: controversy.url
                }}
              />
            </Card>
          </Rows>
        </Wrapper>
      )}
    </Layout>
  )
}

export default Case

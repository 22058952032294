import { log } from 'shared'

import { isBadResponseStatus } from 'api/api.helpers'
import apiClient from '../../apiClient'

const getControversiesCount = async (): Promise<number> => {
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getControversiesCount', { onlineUserId })

  const { count, error, status } = await apiClient.rpc(
    'get_open_controversies_for_user',
    {
      user_id: onlineUserId
    },
    { count: 'exact' }
  )
  if (isBadResponseStatus(status)) {
    throw new Error(`getControversiesCount failed with status: ${status} and error: ${error}`)
  }

  return count ?? 0
}

export default getControversiesCount

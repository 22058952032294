import { FC } from 'react'

import {
  StyledAccordionRoot,
  StyledAccordionHeader,
  AccordeonHeaderLabel,
  AccordeonHeaderLabelText,
  FilterCounter,
  StyledAccordionItem,
  ClearButton,
  StyledAccordionContent
} from './Filter.styles'
import { Divider, ComboBoxOption } from 'components'
import { useGetFilterOptions } from 'data-fetcher/regulations'
import { Regulation } from 'types'

import { FilterList } from './FilterList'

import { RiCloseCircleLine } from 'react-icons/ri'

export type ActiveFilters = Partial<Record<keyof Regulation, string[]>>

type FiltersProps = {
  searchTerm: string
  activeFilters: ActiveFilters
  onActiveFiltersChange: (filters: ActiveFilters) => void
  onFilterClearClicked: (filter: keyof Regulation) => void
}

const regulationParamsBase = {
  search_titlefulltext: null,
  filter_authority: null,
  filter_date_end: null,
  filter_date_start: null,
  filter_document_type: null,
  filter_jurisdiction: null,
  filter_source: null
}
export const Filters: FC<FiltersProps> = ({
  searchTerm,
  activeFilters,
  onActiveFiltersChange,
  onFilterClearClicked
}) => {
  const { data: dataAuthority } = useGetFilterOptions(
    { ...regulationParamsBase, search_titlefulltext: searchTerm },
    'authority'
  )
  const { data: dataSource } = useGetFilterOptions(
    { ...regulationParamsBase, search_titlefulltext: searchTerm },
    'source'
  )
  const { data: dataJurisdiction } = useGetFilterOptions(
    { ...regulationParamsBase, search_titlefulltext: searchTerm },
    'jurisdiction'
  )
  const { data: dataDocumentType } = useGetFilterOptions(
    { ...regulationParamsBase, search_titlefulltext: searchTerm },
    'document_type'
  )

  const handleFilterClick = (filterType: keyof Regulation, filterValue: string) => {
    const updatedFilters = { ...activeFilters }
    const currentFilterValues = updatedFilters[filterType] || []

    // Check if the filterValue is already in the list, if yes, remove it, else add it
    const updatedFilterValues = currentFilterValues.includes(filterValue)
      ? currentFilterValues.filter(value => value !== filterValue)
      : [...currentFilterValues, filterValue]

    updatedFilters[filterType] = updatedFilterValues
    onActiveFiltersChange(updatedFilters)
  }

  const toComboBoxOption = (
    filterWithCount?: Record<string, { values: string | string[]; count: number }> | null
  ): ComboBoxOption[] | undefined => {
    if (!filterWithCount) {
      return undefined
    }
    return Object.entries(filterWithCount).map(([term, { count }]) => ({ term, count }))
  }

  return (
    <StyledAccordionRoot type="multiple" defaultValue={['juristication']}>
      <StyledAccordionItem value="juristication">
        <StyledAccordionHeader>
          <AccordeonHeaderLabel>
            <AccordeonHeaderLabelText>
              {activeFilters.jurisdiction?.length ? (
                <FilterCounter>{activeFilters.jurisdiction?.length}</FilterCounter>
              ) : null}
              <b>Jurisdiction</b>
            </AccordeonHeaderLabelText>
            {activeFilters.jurisdiction?.length ? (
              <ClearButton
                onClick={event => {
                  onFilterClearClicked('jurisdiction')
                  event.preventDefault()
                }}
              >
                <RiCloseCircleLine />
              </ClearButton>
            ) : null}
          </AccordeonHeaderLabel>
        </StyledAccordionHeader>
        <StyledAccordionContent>
          <FilterList
            options={toComboBoxOption(dataJurisdiction)}
            onOptionClick={term => handleFilterClick('jurisdiction', term)}
            activeOptions={activeFilters.jurisdiction}
            title="Jurisdictions"
          />
          <Divider />
        </StyledAccordionContent>
      </StyledAccordionItem>

      <StyledAccordionItem value="source">
        <StyledAccordionHeader>
          <AccordeonHeaderLabel>
            <AccordeonHeaderLabelText>
              {activeFilters.source?.length ? (
                <FilterCounter>{activeFilters.source?.length}</FilterCounter>
              ) : null}
              <b>Source</b>
            </AccordeonHeaderLabelText>
            {activeFilters.source?.length ? (
              <ClearButton
                onClick={event => {
                  onFilterClearClicked('source')
                  event.preventDefault()
                }}
              >
                <RiCloseCircleLine />
              </ClearButton>
            ) : null}
          </AccordeonHeaderLabel>
        </StyledAccordionHeader>
        <StyledAccordionContent>
          <FilterList
            options={toComboBoxOption(dataSource)}
            onOptionClick={term => handleFilterClick('source', term)}
            activeOptions={activeFilters.source}
            title="Sourcees"
          />
          <Divider />
        </StyledAccordionContent>
      </StyledAccordionItem>

      <StyledAccordionItem value="authority">
        <StyledAccordionHeader>
          <AccordeonHeaderLabel>
            <AccordeonHeaderLabelText>
              {activeFilters.authority?.length ? (
                <FilterCounter>{activeFilters.authority?.length}</FilterCounter>
              ) : null}
              <b>Authorities</b>
            </AccordeonHeaderLabelText>
            {activeFilters.authority?.length ? (
              <ClearButton
                onClick={event => {
                  onFilterClearClicked('authority')
                  event.preventDefault()
                }}
              >
                <RiCloseCircleLine />
              </ClearButton>
            ) : null}
          </AccordeonHeaderLabel>
        </StyledAccordionHeader>
        <StyledAccordionContent>
          <FilterList
            options={toComboBoxOption(dataAuthority)}
            onOptionClick={term => handleFilterClick('authority', term)}
            activeOptions={activeFilters.authority}
            title="Authorities"
          />
          <Divider />
        </StyledAccordionContent>
      </StyledAccordionItem>

      <StyledAccordionItem value="doc_type">
        <StyledAccordionHeader>
          <AccordeonHeaderLabel>
            <AccordeonHeaderLabelText>
              {activeFilters.document_type?.length ? (
                <FilterCounter>{activeFilters.document_type?.length}</FilterCounter>
              ) : null}
              <b>Document Type</b>
            </AccordeonHeaderLabelText>
            {activeFilters.document_type?.length ? (
              <ClearButton
                onClick={event => {
                  onFilterClearClicked('document_type')
                  event.preventDefault()
                }}
              >
                <RiCloseCircleLine />
              </ClearButton>
            ) : null}
          </AccordeonHeaderLabel>
        </StyledAccordionHeader>
        <StyledAccordionContent>
          <FilterList
            options={toComboBoxOption(dataDocumentType)}
            onOptionClick={suggestion => handleFilterClick('document_type', suggestion)}
            activeOptions={activeFilters.document_type}
            title="Document Types"
          />
          <Divider />
        </StyledAccordionContent>
      </StyledAccordionItem>
    </StyledAccordionRoot>
  )
}

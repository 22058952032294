import { Layout, TabsContent, TabsList, TabsRoot } from 'components'
import { IrrelevantControversies, OpenControversies, RelevantControversies } from './components'

const Controversies = () => {
  return (
    <Layout>
      <TabsRoot defaultValue="open">
        <TabsList
          tabs={[
            { name: 'Open Insights', value: 'open' },
            { name: 'Irrelevant Insights', value: 'irrelevant' },
            { name: 'Relevant Insights', value: 'relevant' }
          ]}
        />
        <TabsContent value="open">
          <OpenControversies />
        </TabsContent>
        <TabsContent value="irrelevant">
          <IrrelevantControversies />
        </TabsContent>
        <TabsContent value="relevant">
          <RelevantControversies />
        </TabsContent>
      </TabsRoot>
    </Layout>
  )
}

export default Controversies

import { Keys } from 'config'
import { log } from 'shared'
import { Labeling } from 'types'

import apiClient from '../../apiClient'
import { isBadResponseStatus } from '../../api.helpers'
import { Database } from '../../api.types'

const getLabeling = async ({
  controversyId,
  includeAiLabeling,
  userId
}: {
  controversyId?: string
  includeAiLabeling?: boolean
  userId?: string
}): Promise<Array<Labeling>> => {
  if (!controversyId) {
    return []
  }

  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getLabeling')

  let query = apiClient
    .from<Keys.Labeling, Database['public']['Tables'][Keys.Labeling]>(Keys.Labeling)
    .select(
      `
      ai,
      creator,
      end,
      id,
      label,
      start,
      text,
      title,
      with_label:labels(
        with_color:colors(active, id, passive),
        id,
        name
      ),
      with_fingerprint:fingerprints(id, name)
    `
    )
    .eq('controversy', controversyId)

  if (includeAiLabeling) {
    query = query.or(`creator.eq.${userId ?? onlineUserId},ai.eq.true`)
  } else {
    query = query.eq('creator', userId ?? (onlineUserId || ''))
  }

  query.order('start', { ascending: true })

  const { data, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getLabeling failed with status: ${status} and error: ${error}`)
  }

  if (!data) {
    return []
  }

  const defaultLabelings: Labeling[] = []

  const labelings = data?.reduce(
    (prev, { ai, creator, end, id, label, start, text, title, with_label, with_fingerprint }) => {
      if (Boolean(text) === false && Boolean(title) === false) {
        return prev
      }

      const withLabel = Array.isArray(with_label) ? with_label?.[0] : with_label
      const withColor = Array.isArray(withLabel?.with_color)
        ? withLabel?.with_color[0]
        : withLabel?.with_color
      const withFingerprint = Array.isArray(with_fingerprint)
        ? with_fingerprint[0]
        : with_fingerprint

      const labeling: Labeling = {
        ai: ai ?? undefined,
        controversyId,
        color: {
          active: withColor?.active,
          id: withColor?.id,
          passive: withColor?.passive
        },
        creator,
        end,
        fingerprintId: withFingerprint?.id,
        fingerprintName: withFingerprint?.name,
        id,
        labelId: label,
        labelName: withLabel?.name,
        start,
        text: text ?? undefined,
        title: title ?? undefined
      }

      // Filter duplicates with exact labeling start and end
      // ! but prefer user labelings
      const sameLabelingExisting = prev.find(
        labeling =>
          labeling.end === end &&
          labeling.start === start &&
          ((Boolean(text) && labeling.text === text) ||
            (Boolean(title) && labeling.title === title))
      )

      if (sameLabelingExisting) {
        if (sameLabelingExisting.ai) {
          return [...prev.filter(({ id }) => id !== sameLabelingExisting.id), labeling]
        } else {
          return prev
        }
      }

      return [...prev, labeling]
    },
    defaultLabelings
  )

  return labelings
}

export default getLabeling
